import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { DangerouslySetInnerHtml } from 'layout';
import { BANNER_BACKGROUND_COLOR } from 'utils/constants';

import { SimpleBannerProps } from './models';

import './SimpleBanner.scss';

const SimpleBanner: FC<SimpleBannerProps> = ({
  description,
  title,
  background = BANNER_BACKGROUND_COLOR.pink,
}) => (
  <Row data-testid="simple-banner" className={`simple-banner simple-banner--${background}`}>
    <Col>
      <h1 className="simple-banner__title">{title}</h1>
      <DangerouslySetInnerHtml
        html={description}
        className="simple-banner__description"
        data-testid="simple-banner-description"
      />
    </Col>
  </Row>
);

export default SimpleBanner;

import React, { FC } from 'react';
import { Link } from 'gatsby';

import './ListTree.scss';

const ListTree: FC<ListTreeProps> = ({ items, isChild = false }) => (
  <>
    {items.map(({ id, path, name, children }) => (
      <ul key={id} className="list-tree" data-testid="list-tree">
        <li className="list-tree__item">
          <Link className="list-tree__link" to={path}>
            {!isChild ? <h2>{name}</h2> : name}
          </Link>
          {children.length > 0 ? <ListTree items={children} isChild /> : null}
        </li>
      </ul>
    ))}
  </>
);

export default ListTree;

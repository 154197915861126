import { graphql, useStaticQuery } from 'gatsby';

import { DEFAULT_EXCLUDED_PAGES, DEFAULT_NAME_OVERRIDE } from './constants';
import { SiteNode, SitePageQueryResult, TreeItem, UseSiteTreeParams } from './model';
import { createTree } from './utils';

const useSiteTree = ({
  lang = null,
  exclude = DEFAULT_EXCLUDED_PAGES,
  nameOverride = DEFAULT_NAME_OVERRIDE,
}: UseSiteTreeParams = {}): TreeItem[] => {
  const { allSitePage }: SitePageQueryResult = useStaticQuery(graphql`
    query AllSitePageQuery {
      allSitePage(filter: { context: { seoNoIndex: { ne: true } } }) {
        nodes {
          id
          path
          context {
            lang
            name
            seoTitle
          }
        }
      }
    }
  `);

  const nodes: SiteNode[] = allSitePage.nodes
    .filter((item) => {
      const LANG_NON_DEFINED = null;

      if (item.context?.lang && lang) {
        return [lang, LANG_NON_DEFINED].includes(item.context?.lang);
      }

      return true;
    })
    .map(({ id, path, context }) => ({
      // Using SEO title instead of name cause I discovered that in CMS version used on Vanish there's a bug causing API to always return main language name instead of lang-specific one
      name: context?.seoTitle,
      id,
      path,
    }));

  return createTree({
    nodes,
    exclude,
    nameOverride,
  });
};

export default useSiteTree;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import useSiteTree, { DEFAULT_EXCLUDED_PAGES } from 'hooks/useSiteTree';

import { Col, Row } from 'layout';
import Layout from 'layout/Layout';
import ListTree from 'components/ListTree';
import SimpleBanner from 'components/SimpleBanner';
import Teaser from 'components/Teaser';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { SitemapPageProps } from './models';

const SitemapPage: FC<SitemapPageProps> = ({
  data: {
    sitemapPage: { seo, langProps, teaser, simpleBanner },
    allSitePage: { nodes },
    languageSelector: { languages },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const tree = useSiteTree({
    lang: langProps.lang,
    exclude: [...DEFAULT_EXCLUDED_PAGES, ...languages.map(({ path }) => path)],
  });

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      <SimpleBanner {...simpleBanner[0]} />
      <Row>
        <Col>
          <div className="sitemap-container">
            <ListTree items={tree} />
          </div>
        </Col>
      </Row>
      <Teaser {...teaser[0]} />
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    sitemapPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      simpleBanner {
        ...SimpleBannerFragment
      }
      teaser {
        ...TeaserFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
    languageSelector {
      languages {
        path
      }
    }
  }
`;

export default SitemapPage;
